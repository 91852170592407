import React from 'react'
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({ data, className, children }) => {
  // Set ImageData.
  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={data}
      backgroundColor={`#091d2d`}
    >
      {children}
    </BackgroundImage>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  display: ${ props => props.className === "showOnMobile" ? "none" : "flex" };
  align-items: center;
  justify-content: center;
  min-height: 470px;
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  @media (max-width: 768px) {
    display: ${ props => props.className === "hideOnMobile" ? "none" : "flex" };

    &::before,
    &::after {
      background-position: center center;
    }
    h2,
    p {
      text-shadow: 0 5px 20px #333333;
    }
  }
`;

export default StyledBackgroundSection;
