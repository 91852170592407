import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Text = styled.span`
  display: block;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 3px;
  line-height: 48px;
  text-transform: uppercase;
  ${MEDIA.TABLET`
    line-height: 34px!important;
  `};

  ${MEDIA.PHONE`
    margin-bottom: 0;
    font-size: 20px;
    line-height: 34px!important;
  `};
`;
