import styled from 'styled-components';
import { Link } from 'gatsby';

export const Container = styled(Link)`
  margin: 0;
  padding: 10px 16px;
  background: transparent;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
  transition: all 0.3s ease;
  text-align: center;
  text-transform: uppercase;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background: #e53935;
      border: 1px solid #e53935;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &:focus {
    outline: 0;
  }

  &:disabled,
  &[disabled] {
    background: #ffffff;
    cursor: default;
    &:hover {
      cursor: default;
      box-shadow: none;
      transform: scale(1);
    }
  }

  &.button--primary {
    background: #e53935;
    border: none;
    color: #ffffff;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: #bd211f;
      }
    }
    &[disabled] {
      background: #bd211f;
      opacity: 0.5;
    }
  }

  &.button--text {
    padding-left: 8px;
    background: #ffffff;
    border: none;
    color: #573bee;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: #ecf5ff;
      }
    }
    &[disabled] {
      background: #ecf5ff;
      opacity: 0.5;
    }
  }
`;
