import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './button.css';

const Button = ({ children, link, className }) => {
  return (
    <Container type="button" className={className} to={link}>
      {children}
    </Container>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Button;
