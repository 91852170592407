import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: 100px 0;
  width: 1140px;
  background: none;
  @media (max-width: 768px) {
    display: none;
    width: auto;
    height: 500px;
    margin-right: 24px;
    margin-left: 24px;
    padding: 24px 0;
  }

  h1 {
    margin-bottom: 16px;
    width: 410px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    color: #000000;
    text-transform: uppercase;
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }

  p {
    margin-bottom: 48px !important;
    width: 410px;
    margin: 0;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #000000;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .buttonWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    max-width: 210px;
    @media (max-width: 768px) {
      max-width: auto;
      margin-bottom: 50px;
    }
  }

  &.hideOnMobile {
    display: flex;
    @media (max-width: 768px) {
      display: none !important;
    }
  }

  &.showOnMobile {
    display: none;
    @media (max-width: 768px) {
      display: flex !important;
    }
  }
`;
