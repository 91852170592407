import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import StyledBackgroundSection from 'components/backgroundSection';

import { Container } from './hero.css';

const Hero = ({ hero }) => {
  const { title, content, image, action } = hero;
  return (
    <StyledBackgroundSection
      data={image.childImageSharp.fluid}
      className="hideOnMobile"
    >
      <Container>
        <div className="d-flex flex-column">
          <h1>{title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
            }}
          />
          {action && (
            <div className="buttonWrapper">
              <Button className="button--primary" link="/contact">
                {action}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </StyledBackgroundSection>
  );
};

Hero.propTypes = {
  hero: PropTypes.object.isRequired,
};

export default Hero;
